<template>
  <div
    class="imgfocus"
    @mousemove="handleMousemove"
    @mouseout="handleMouseout"
  >
    <div class="imgbox">
      <div
        v-for="(item, idx) in imgs"
        :key="idx"
        :style="{
          display: bannerIndex === idx ? 'block' : 'none',
          cursor: item.canGo ? 'pointer' : ''
        }"
        class="decoimg_b2"
        @click="handleGo(item)"
      >
        <div>
          <img
            v-if="$language === 'cn'"
            :src="baseURL + item.url"
            :style="{ opacity: item.opacity }"
          >
          <img
            v-else
            :src="baseURL + item.urlEn"
            :style="{ opacity: item.opacity }"
          >
        </div>
      </div>
    </div>
    <ul class="num_a2">
      <li v-for="(item, idx) in imgs" :key="idx" @mousemove="handleIdxMousemove(idx)">
        <a :class="{ current: bannerIndex === idx }" hidefocus="true" target="_self" />
      </li>
    </ul>
  </div>
</template>

<script>
//
export default {
  props: {
    imgs: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      loading: false,
      bannerIndex: 0,
      interval1: null,
      interval2: null,
      baseURL: process.env.VUE_APP_BASE_API
    }
  },
  created() {
    if (this.baseURL === 'http://106.75.237.135:27980') {
      this.baseURL = 'https://www.c-ceec.org.cn'
    }
    this.handleStartInterval()
  },
  methods: {
    //  鼠标移入banner角标
    handleIdxMousemove(idx) {
      if (this.bannerIndex !== idx) {
        this.bannerIndex = idx
        this.handleBannnerChange()
      }
    },
    //  鼠标移入bannner
    handleMousemove() {
      if (this.interval1) {
        clearInterval(this.interval1)
      }
    },
    //  鼠标移出bannner
    handleMouseout() {
      this.handleStartInterval()
    },
    //  banner计时器
    handleStartInterval() {
      if (this.interval1) {
        clearInterval(this.interval1)
      }
      this.interval1 = setInterval(() => {
        if (this.bannerIndex > this.imgs.length - 2) {
          this.bannerIndex = 0
        } else {
          this.bannerIndex++
        }
        this.handleBannnerChange()
      }, 2500)
    },
    //  banner角标变化
    handleBannnerChange() {
      const item = this.imgs[this.bannerIndex]
      item.opacity = 0
      if (this.interval2) {
        clearInterval(this.interval2)
      }
      this.interval2 = setInterval(() => {
        item.opacity = item.opacity + 0.01
        if (item.opacity >= 1) {
          clearInterval(this.interval2)
        }
      }, 0)
    },
    // 跳转
    handleGo(item) {
      if (item.canGo) {
        this.$router.push({ path: '/dynamic/notice' })
        // if (this.$language === 'cn') {
        //   this.$router.push({ path: '/dynamic/noticeDetails', query: {id: 392}})
        // } else {
        //   this.$router.push({ path: '/dynamic/noticeDetails', query: {id: 132}})
        // }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .imgfocus {
    position: relative;
    width: 1200px;
    .imgbox {
      .decoimg_b2 {
        position: relative;
        width: 100%;
        float: left;
        div {
          border: 0;
          text-decoration: none;
          img {
            display: block;
            width: 1200px;
            height: 312px;
          }
        }
      }
    }
    .num_a2 {
      position: absolute;
      top: 280px;
      margin: 0 auto;
      left: 50%;
      transform: translate(-50%,-50%);
      li {
        position: relative;
        float: left;
        margin-right: 20px;
        width: 10px;
        height: 10px;
      }
      a {
        width: 100%;
        height: 100%;
        line-height: 100%;
        border: 1px #fff solid;
        border-radius: 50%;
        background-color: #808285;
        position: absolute;
        -webkit-transition: -webkit-transform 0.5s ease, background-color 0.5s ease;
        transition: transform 0.5s ease, background-color 0.5s ease;
      }
      .current {
        color: #4D4D4F;
        cursor: pointer;
        background-color: #1890ef;
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
    }
  }
</style>
