/**
 * 删除富文本内的标签
 * @param content
 * @returns {*}
 */
export function removeHTMLTag(content) {
  const text = content.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n').replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '').replace(/(\n)?<\/([^>]+)>/g, '').replace(/\u00a0/g, ' ').replace(/&nbsp;/g, ' ').replace(/<\/?(img)[^>]*>/gi, '').replace(/&amp;/g,"&").replace(/&lt;/g,"<").replace(/&gt;/g,">").replace(/&#39;/g,"\'").replace(/&quot;/g,"\"").replace(/<\/?.+?>/g,"")
  return text
}

/**
 * 获取字符串 AxxxA 内的 {xxx}
 * @param str
 * @returns {Array}
 */
export function getExecAStrs (str) {
  const reg = /A(.+?)A/g
  let list = []
  let result = null
  do {
    result = reg.exec(str)
    result && list.push(result[1])
  } while (result)
  return list
}

export function getExecBStrs (str) {
  const reg = /B(.+?)B/g
  let list = []
  let result = null
  do {
    result = reg.exec(str)
    result && list.push(result[1])
  } while (result)
  return list
}

//  国家
export function formatCountry(val, countryOptions, language) {
  const obj = countryOptions.find(item => item.dkey === val)
  if (obj) {
    if (language === 'cn') {
      return obj.dvalue
    } else {
      return obj.english
    }
  }
  return val
}

//  行业
export function formatIndustryName(val, options, language) {
  const obj = options.find(item => item.id === val)
  // console.log(obj)
  if (obj) {
    if (language === 'cn') {
      return obj.classifyName
    } else {
      return obj.english
    }
  }
  return val
}
