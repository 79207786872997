<template>
  <div class="page-box">
    <!-- 副标题 -->
    <div class="threeF">
      <div>
        <!-- 中东欧技术供给 -->
        <span v-if="type === '1'">
          {{ $t('m.navbar.ceec_cooperate_text') }}
        </span>
        <!-- 中东欧合作需求 -->
        <span v-else-if="type === '2'">
          {{ $t('m.navbar.china_cooperate_text') }}
        </span>
        <!-- 项目合作案例 -->
        <span v-else-if="type === '3'">
          {{ $t('m.navbar.project_cooperate_text') }}
        </span>
        <!-- 创新机构介绍 -->
        <span v-else>
          {{ $t('m.navbar.innovation_institution_text') }}
        </span>
      </div>
      <div style="clear: both;" />
      <div class="bottom_line" />
    </div>
    <div v-for="item in list" :key="item.id">
      <ul class="technology_one">
        <li style="display: flex; align-items: center;">
          <div class="dot-box"></div>
        </li>
        <li>
          <a
            v-if="type !== '4'"
            @click="handleClickItem(item)"
          >{{ item.title }}</a>
          <a
            v-else
            @click="handleClickItem(item)"
          >{{ item.orgName }}</a>
        </li>
        <li :style="{fontSize: language === 'cn' ? '13px' : '13px'}">
          {{ type === '4' ? item.publishTime : item.time }}
        </li>
      </ul>
      <!-- 1：中东欧合作需求、2：中方合作需求、2：项目合作案例 -->
      <div v-if="type === '1'" class="medic_y">
        {{ formatCountry(item.country, countryOptions, language) }}
      </div>
    </div>
    <div style="clear:both;"></div>
    <!-- 更多 -->
    <div
      :style="{ paddingTop: type === '2' || type === '3' ? '12px' : '10px'}"
      class="faBmore"
    >
      <a
        @click="handleViewMore"
      >{{ $t('m.more_text') }}>></a>
    </div>
  </div>
</template>

<script>
  //
  import { formatCountry } from '@/utils/tool'
  export default {
    props: {
      type: {
        type: String,
        default: ''
      },
      list: {
        type: Array,
        default: () => []
      },
      countryOptions: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        language: this.$language
      }
    },
    methods: {
      formatCountry,
      //  需求、文案点击
      handleClickItem(item) {
        this.$router.push({ path: '/cooperate/details', query: {id: item.id, type: this.type}})
      },
      //  更多
      handleViewMore() {
        if (this.type === '1') {
          // 中东欧合作需求
          this.$router.push({ path: '/cooperate' })
        } else if (this.type === '2') {
          // 中方合作需求
          this.$router.push({ path: '/cooperate/china' })
        } else if (this.type === '3') {
          // 项目合作需求
          this.$router.push({ path: '/cooperate/project' })
        } else if (this.type === '4') {
          // 创新机构介绍
          this.$router.push({ path: '/cooperate/innovation' })
        } else if (this.type === '5') {
          // 中东欧合作供给
          this.$router.push({ path: '/cooperate/tech/supply/ceec' })
        }
      }
      //
      // formatCountry(val) {
      //   const obj = this.countryOptions.find(item => item.id === val)
      //   if (obj) {
      //     if (this.$language === 'cn') {
      //       return obj.dvalue
      //     } else {
      //       return obj.english
      //     }
      //   }
      //   return val
      // }
    }
  }
</script>

<style lang="scss" scoped>
  .dot-box {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #73be1e;
  }
  .page-box {
    width: 380px;
    vertical-align: top;
  }
  .threeF {
    width: 380px;
    margin-top: 20px;
    span {
      color: #73be1e;
      font-size: 16px;
    }
    .bottom_line {
      margin-top: 10px;
      width: 380px;
      border-bottom: 1px #cccccc solid;
    }
  }
  .threeF div:nth-child(1) {
    width: 276px;
    float: left;
  }
  .technology_one {
    /*height: 35px;*/
    li {
      width: 340px;
      margin-top: 15px;
      float: left;
      line-height: 20px;
      font-size: 13px;
      /*border: 1px solid red;*/
      /*background-color: red;*/
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
  }
  .technology_one li:nth-child(1) {
    width: 10px;
    height: 20px;
    margin-right: 5px;
    font-size: 13px;
  }
  .technology_one li:nth-child(2) {
    width: 230px;
    height: 20px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
    /*border: 1px solid red;*/
    a {
      color: #343434;
      text-decoration: none;
    }
  }
  .technology_one li:nth-child(3) {
    width: 100px;
    height: 20px;
    float: right;
    right: 10px;
    /*font-size: 12px !important;*/
    padding-left: 27px;
    text-align: right;
    /*border: 1px solid red;*/
  }
  /*.technology_one li:nth-child(1) {*/
    /*width: 10px;*/
    /*height: 20px;*/
    /*margin-right: 5px;*/
    /*font-size: 13px;*/
  /*}*/
  /*.technology_one li:nth-child(2) {*/
    /*width: 230px;*/
    /*height: 20px;*/
    /*font-size: 13px;*/
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*padding-left: 5px;*/
    /*a {*/
      /*color: #343434;*/
      /*text-decoration: none;*/
    /*}*/
  /*}*/
  /*.technology_one li:nth-child(3) {*/
    /*width: 100px;*/
    /*height: 20px;*/
    /*float: right;*/
    /*right: 10px;*/
    /*text-align: right;*/
  /*}*/
  .medic_y {
    font-size: 12px;
    color: #676767;
    margin-left: 20px;
    /*padding-top: 10px;*/
    height: 53px;
    /*border: 1px solid red;*/
  }
  .faBmore {
    width: 320px;
    a {
      color: #2890eb;
      margin-left: 20px;
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  a {
    cursor: pointer;
  }
</style>
