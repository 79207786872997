<template>
  <div>
    <!-- title -->
    <div class="title_leftm">
      <div style="float: left;">
        <span class="span_title">{{ this.$language === 'cn' ? ' 新闻动态' : 'News' }}</span>
        <span v-if="this.$language === 'cn'" class="span_sub_title"> / News</span>
      </div>
      <a @click="handleGoDynamic">{{ $t('m.more_text') }}>></a>
    </div>
    <!-- list -->
    <div v-for="(item, idx) in news" :key="item.id">
      <div class="newDt01">
        <!-- 日期 -->
        <div class="dataNum">
          <span class="dataNum01">{{ formatDate(item.time, 'day') }}</span>
          <br>
          <div class="dataNum02">{{ formatDate(item.time) }}</div>
        </div>
        <!-- 新闻 -->
        <div class="newsCOn">
          <!-- 点 -->
          <div class="circlOne" />
          <!-- 标题 -->
          <div class="newcenter_biaot">
            <a @click="handleGoDynamicDetails(item)">{{ item.title }}</a>
          </div>
          <!-- 内容 -->
          <div class="newcenter_p">
            <span v-if="$language === 'cn'">
              {{ item.body && item.body.replace(/\s+/g, '') }}
            </span>
            <span>
              {{ item.body }}
            </span>
          </div>
        </div>
        <!-- -->
        <div style="clear: both;" />
      </div>
      <div v-if="idx !== news.length - 1" class="centerL" />
    </div>
  </div>
</template>

<script>
//
export default {
  props: {
    news: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      body: '　　2022年5 月11日，在科技部国际合作司和江苏省科技厅的大力指导和支持下，由中国—中东欧国家技术转移中心具体承办的“首届中国—中东欧国家技术合作交流大会”在江苏南京成功举办。科技部国际合作司副司长陈霖豪线上出席大会并致辞，江苏省科技厅厅长王秦、副厅长过利平，江苏省生产力促进中心主任、江苏省对外科学技术交流中心主任赵志强出席了大会。本届大会是中国—中东欧国家技术转移中心成立以来举办的规模较大的跨境线上交流大会，以促进中国与中东欧国家产业创新合作为目的，组织一系列“云”上中外技术合作项目路演、“云”上视频对接交流等务实活动。中东欧国家高校、技术转移机构、企业等单位代表约50人线上以视频连线方式参会，超过26万人在线观看了大会直播。'
    }
  },
  created() {
    this.news.forEach(item => {
      console.log(item)
    })
  },
  methods: {
    //  详情
    handleGoDynamicDetails(item) {
      if (item.link) {
        // 外链
        const dom = document.createElement('a') // 创建a标签
        dom.setAttribute('target', '_blank')  // href链接
        dom.setAttribute('href', item.link)  // href链接
        dom.click() // 自执行点击事件
      } else {
        // 非外链
        this.$router.push({ path: '/dynamic/details', query: {id: item.id}})
      }
    },
    //  更多
    handleGoDynamic() {
      this.$router.push({ path: '/dynamic' })
    },
    //
    formatDate(date, type) {
      const arr = date.split('-')
      if (type === 'day') {
        return arr[2]
      } else {
        return arr[0] + '-' + arr[1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  /* list_item */
  .newDt01 {
    width: 100%;
    height: 75px;
    margin-top: 10px;
    /* 日期 */
    .dataNum {
      width: 75px;
      height: 66px;
      background-color: #2890eb;
      text-align: center;
      font-family: arial;
      float: left;
      padding-top: 9px;
      .dataNum01 {
        font-size: 30px;
        color: #fff;
        text-align: center;
      }
      .dataNum02 {
        margin-top: 5px;
        color: #fff;
        text-align: center;
      }
    }
    /* 主体 */
    .newsCOn {
      float: left;
      margin-left: 12px;
      /* 点 */
      .circlOne {
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #333333;
        vertical-align: top;
        margin-right: 10px;
        margin-top: 6px;
      }
      /* 标题 */
      .newcenter_biaot {
        display: inline-block;
        font-size: 14px;
        color: #000;
        font-weight: 500;
        /*width: 290px;*/
        width: calc(360px - 110px);
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        a {
          color: #333;
          cursor: pointer;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      /* 内容 */
      .newcenter_p {
        /*width: 310px;*/
        width: calc(360px - 95px);
        height: 42px;
        font-size: 12px;
        padding-top: 7px;
        /*word-wrap: break-word !important;*/
        /*word-break: break-all !important;*/
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        display: -webkit-box !important;
        -webkit-box-orient: vertical !important;
        -webkit-line-clamp: 2 !important;
        span {
          font-size: 12px !important;
          background-color: #ffffff !important;
          font-family: "microsoft yahei" !important;
          font-weight: normal !important;
          color: #666666 !important;
          text-decoration: none !important;
          text-shadow: none !important;
          font-style: normal;
          display: block;
          margin: 0px !important;
          /*word-wrap: break-word !important;*/
          /*word-break: break-all !important;*/
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          display: -webkit-box !important;
          -webkit-box-orient: vertical !important;
          -webkit-line-clamp: 2 !important;
          line-height: 20px;
        }
      }
    }
  }
  /* 分割线 */
  .centerL {
    width: 100%;
    border: 0.5px #ccc dashed;
    margin-top: 15px;
    margin-bottom: 10px;
  }
</style>
