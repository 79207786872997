import request from '@/utils/request'

//  中东欧合作需求
export function cceecSupplyList(params) {
  return request({
    url: '/api/cceec/supplyList',
    method: 'get',
    params: params
  })
}

//  中东欧合作需求详情
export function supplyDetails(id, params) {
  return request({
    url: '/api/cceec/supplyView/' + id,
    method: 'get',
    params: params
  })
}


//  中方合作需求
export function cceecDemandList(params) {
  return request({
    url: '/api/cceec/demandList',
    method: 'get',
    params: params
  })
}

//  中方合作需求详情
export function demandDetails(id, params) {
  return request({
    url: '/api/cceec/demandView/' + id,
    method: 'get',
    params: params
  })
}

//  技术转移案例列表
export function cooperateCaseList(params) {
  return request({
    url: '/api/cceec/caseList',
    method: 'get',
    params: params
  })
}

//  技术转移案例详情
export function caseDetails(id, params) {
  return request({
    url: '/api/cceec/caseView/' + id,
    method: 'get',
    params: params
  })
}

// 创新机构列表
export function orgList(params) {
  return request({
    url: '/api/cceec/orgList',
    method: 'get',
    params: params
  })
}
// 创新机构列表详情
export  function orgDetails(id, params) {
  return request({
    url: '/api/cceec/orgView/' + id,
    method: 'get',
    params: params
  })
}
