<template>
  <div class="app-container">
    <!-- 轮播图 -->
    <banner-box :imgs="imgs" style="margin-top: 4px;" />
    <div style="clear: both;" />
    <div class="activity_bar" @click="handleGoPersonal">
      <img style="margin-left: -10px;" src="./images/gif/bg_3.gif">
      <img v-if="$language === 'cn'" class="text_bar" src="./images/gif/text_cn.png">
      <img v-else class="text_bar" src="./images/gif/text_en.png">
      <!--<img v-if="$language === 'cn'" class="bar_bg" src="./images/activity_bar.png">-->
      <!--<img v-else class="bar_bg" src="./images/activity_bar_en.png">-->
      <div class="look_btn">
        {{ $language === 'cn' ? '立即查看' : 'View' }}
      </div>
    </div>
    <!-- -->
    <div class="news_country_box">
      <!-- 新闻动态 -->
      <div class="newcenter_left">
        <news-box :news="news" />
      </div>
      <!-- 通知公告 -->
      <div class="newcenter_left">
        <notice-box :news="notices" />
      </div>
      <!-- 合作国家 -->
      <div class="country_right">
        <!-- title -->
        <div class="title_leftm">
          <div style="float: left;">
            <span class="span_title">{{ this.$language === 'cn' ? '合作国家' : 'Cooperation Countries' }}</span>
            <span v-if="this.$language === 'cn'" class="span_sub_title"> / Cooperation Countries</span>
          </div>
        </div>
        <country-box style="margin-top: 10px;" />
      </div>
    </div>
    <div style="clear: both;" />
    <!-- -->
    <div style="width: 1200px; margin-top: 40px;">
      <!-- title -->
      <div class="title_leftm">
        <div style="float: left;">
          <!-- 合作信息 -->
          <span class="span_title">{{ this.$language === 'cn' ? '合作伙伴' : 'Partners' }}</span>
          <span v-if="this.$language === 'cn'" class="span_sub_title"> / Partners</span>
        </div>
      </div>
      <!-- -->
      <div style="padding-top: 20px;">
        <vue-seamless-scroll :data="partners" :class-option="defaultOption" class="seamless-warp">
          <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
            <el-card v-for="(item, idx) in partners" :key="idx" style="width: 24%; margin-bottom: 20px;">
              <div style="display: flex; align-items: center;">
                <img
                  :src="item.img"
                  style="width: 60px; height: 60px; border-radius: 40px;"
                >
                <div
                  v-if="$language === 'cn'"
                  class="text_2"
                >{{ item.name }}</div>
                <div
                  v-else
                  class="text_2"
                >{{ item.english }}</div>
              </div>
            </el-card>
            <div style="width: 24%; height: 10px;" />
            <div style="width: 24%; height: 10px;" />
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
    <!-- -->
    <div style="width: 1200px; margin-top: 40px;">
      <!-- title -->
      <div class="title_leftm">
        <div style="float: left;">
          <!-- 合作信息 -->
          <span class="span_title">{{ this.$language === 'cn' ? '合作信息' : 'Cooperation Information' }}</span>
          <span v-if="this.$language === 'cn'" class="span_sub_title"> / Cooperation Information</span>
        </div>
      </div>
      <!-- -->
      <div style="display: flex; justify-content: space-between;">
        <!-- 中东欧合作需求 -->
        <need-box-one
          type="1"
          :list="listL"
          :country-options="countryOptions"
        />
        <!-- 中方合作需求 -->
        <need-box-one
          type="2"
          :list="listC"
          :country-options="countryOptions"
        />
        <!-- 科技资讯 -->
        <need-box-one
          type="3"
          :list="listR"
          :country-options="countryOptions"
        />
        <!-- 创新结构介绍 -->
        <!--<need-box-one-->
          <!--type="4"-->
          <!--:list="list4"-->
          <!--:country-options="countryOptions"-->
        <!--/>-->
      </div>
    </div>
  </div>
</template>

<script>
//
import { newsList, noticeList } from '@/api/cceec/news'
import { countryList } from '@/api/cceec/constant'
import { cceecSupplyList, cceecDemandList, cooperateCaseList, orgList } from '@/api/cceec/cooperate'
import { removeHTMLTag } from '@/utils/tool'

import BannerBox from './components/bannerbox'
import NewsBox from './components/newsbox'
import NoticeBox from './components/noticebox'
import CountryBox from '@/components/CountryBox'
import NeedBoxOne from './components/needboxone2'
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: 'Home',
  components: {
    BannerBox,
    NewsBox,
    NoticeBox,
    CountryBox,
    NeedBoxOne,
    vueSeamlessScroll
  },
  data() {
    return {
      loading: false,
      imgs: [
        // {
        //   url: '/file/upload/tech/tc/20230725.jpg',
        //   urlEn: '/file/upload/tech/tc/20230725en.jpg',
        //   opacity: 1,
        //   canGo: true
        // },
        {
          url: '/file/upload/tech/tc/b7vC0WPBZjEOL4c52xrf.png',
          urlEn: '/file/upload/tech/tc/b7vC0WPBZjEOL4c52xrf.png',
          opacity: 1
        },
        {
          url: '/file/upload/tech/tc/20211224142929648.jpg',
          urlEn: '/file/upload/tech/tc/20211224142929648.jpg',
          opacity: 1
        },
        {
          url: '/file/upload/tech/tc/20211224143029140.jpg',
          urlEn: '/file/upload/tech/tc/20211224143029140.jpg',
          opacity: 1
        },
        {
          url: '/file/upload/tech/tc/20211224143058825.jpg',
          urlEn: '/file/upload/tech/tc/20211224143058825.jpg',
          opacity: 1
        },
        {
          url: '/file/upload/tech/tc/20211224142439146.jpg',
          urlEn: '/file/upload/tech/tc/20211224142439146.jpg',
          opacity: 1
        },
        {
          url: '/file/upload/tech/tc/20211224150919122.jpg',
          urlEn: '/file/upload/tech/tc/20211224150919122.jpg',
          opacity: 1
        },
        {
          url: '/file/upload/tech/tc/20211224143240531.jpg',
          urlEn: '/file/upload/tech/tc/20211224143240531.jpg',
          opacity: 1
        }
      ],
      // 新闻动态
      news: [],
      // 大会咨询
      notices: [],
      // 中东欧合作需求
      listL: [],
      // 中方合作需求
      listC: [],
      // 项目合作案例
      listR: [],
      // 创新结构介绍
      list4: [],
      countryOptions: [],
      partners: this.$constant.partnerOptions,
      partners2: []
    }
  },
  computed: {
    defaultOption() {
      return {
        step: 0.5,
        hoverStop: true
      }
    }
  },
  created() {
    // for (let i = 0; i < 4; i++) {
    //   this.partners.forEach(item => {
    //     this.partners2.push(item)
    //   })
    // }
    // 新闻动态
    this.getNews()
    // 大会咨询
    this.getNoticeList()
    // 中东欧合作需求
    this.getCCEECList()
    // 中方合作需求
    this.getChinaList()
    // 合作案例
    this.getCaseList()
    // 创新机构介绍
    this.getNewOrgList()
    // 国家
    this.getCountryList()
  },
  methods: {
    // 新闻动态
    getNews() {
      const data = {
        pageNum: 1,
        pageSize: 4,
        languageType: this.$languageType
      }
      newsList(data).then(res => {
        const list = res.data.list
        list.forEach(item => {
          if (item.body) {
            item.body = removeHTMLTag(item.body)
          }
        })
        this.news = list
        console.log(this.news)
      })
    },
    // 大会咨询
    getNoticeList() {
      const data = {
        pageNum: 1,
        pageSize: 4,
        languageType: this.$languageType
      }
      noticeList(data).then(res => {
        console.log(res, '=============')
        const list = res.data.list
        this.notices = list
      })
    },
    // 中东欧合作需求
    getCCEECList() {
      const data = {
        pageNum: 1,
        pageSize: 4,
        languageType: this.$languageType
      }
      cceecDemandList(data).then(res => {
        // console.log(res)
        this.listL.length = 0
        const list = res.data.list
        list.forEach(item => {
          const it = {
            id: item.id,
            title: item.demandName,
            time: item.publishTime,
            country: item.nation
          }
          this.listL.push(it)
        })
      })
      // const data = {
      //   pageNum: 1,
      //   pageSize: 4,
      //   languageType: this.$languageType
      // }
      // cceecSupplyList(data).then(res => {
      //   // console.log(res)
      //   this.listL.length = 0
      //   const list = res.data.list
      //   list.forEach(item => {
      //     const it = {
      //       id: item.id,
      //       title: item.supplyName,
      //       time: item.publishTime,
      //       country: item.nation
      //     }
      //     this.listL.push(it)
      //   })
      // })
    },
    // 中方合作需求
    getChinaList() {
      const data = {
        pageNum: 1,
        pageSize: 6,
        nation: 1,
        languageType: this.$languageType
      }
      cceecDemandList(data).then(res => {
        // console.log(res)
        this.listC.length = 0
        const list = res.data.list
        list.forEach(item => {
          const it = {
            id: item.id,
            title: item.demandName,
            time: item.publishTime,
            country: item.nation
          }
          this.listC.push(it)
        })
      })
    },
    // 项目合作案例
    getCaseList() {
      const data = {
        pageNum: 1,
        pageSize: 6,
        languageType: this.$languageType
      }
      cooperateCaseList(data).then(res => {
        // console.log(res)
        this.listR = res.data.list
      })
    },
    // 创新机构介绍
    getNewOrgList() {
      const data = {
        pageNum: 1,
        pageSize: 6,
        languageType: this.$languageType
      }
      orgList(data).then(res => {
        this.list4 = res.data.list
      })
    },
    // 国家
    getCountryList() {
      countryList().then(res => {
        console.log(res)
        this.countryOptions = res.data
      })
    },
    handleGoPersonal() {
      if (this.$language === 'cn') {
        // 当前为中文网站
        window.location.href = process.env.VUE_APP_PERSONAL_CN_WEB_URL
      } else {
        // 当前为英文网站
        window.location.href = process.env.VUE_APP_PERSONAL_EN_WEB_URL
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .seamless-warp {
    height: 200px;
    overflow: hidden;
  }
  .news_country_box {
    display: flex;
    width: 1200px;
    justify-content: space-between;
    margin-top: 30px;
  }
  .newcenter_left {
    /*float: left;*/
    /*width: 455px;*/
    width: 360px;
  }
  .country_right {
    /*float: right;*/
    /*width: 700px;*/
    width: 440px;
  }
  .text_2 {
    margin-left: 10px;
    width: 198px;
    font-size: 13px !important;
    background-color: #ffffff !important;
    font-weight: bolder !important;
    color: #333333 !important;
    text-decoration: none !important;
    text-shadow: none !important;
    font-style: normal;
    display: block;
    /*word-wrap: break-word !important;*/
    /*word-break: keep-all !important;*/
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 3 !important;
    line-height: 20px;
    max-height: 60px;
  }
  /deep/ .el-card {
    .el-card__body {
      padding: 10px;
    }
  }
  .activity_bar {
    position: relative;
    margin-top: 10px;
    width: 1200px;
    height: 92px;
    border-radius: 5px;
    cursor: pointer;
    overflow: hidden;
    /*border: 1px solid red;*/
    .text_bar {
      position: absolute;
      top: 0;
      left: 170px;
      bottom: 0;
      margin: auto 0;
      width: 60%;
      height: auto;
    }
    .bar_bg {
      width: 100%;
      height: auto;
    }
    .look_btn {
      position: absolute;
      top: 30px;
      left: 967px;
      padding: 0 20px;
      line-height: 32px;
      color: #ffffff;
      /*border: 1px solid #ffffff;*/
      border-radius: 16px;
      background: -webkit-linear-gradient(#37759E, #37759E);
      background: -o-linear-gradient(#37759E, #37759E);
      background: -moz-linear-gradient(#37759E, #37759E);
      background: linear-gradient(#37759E, #37759E);
    }
  }
</style>
